import * as React from 'react'

import { graphql } from 'gatsby';

import Layout from '../components/Layout'
import Seo from '../components/Seo';

const PraxenPage = ({ data }) => {

  return (
    <Layout
      currentMenuItem='praxen'
    >
      <Seo
        title={'Praxen'}
        description={'Erfahre wie du u1Dent.app® in deiner Praxis einsetzen kannst, um die Qualifizierung von Mitarbeitern/ZFA/Azubis zu verbessern.'}
      />
      <div className="mx-auto bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden md:max-w-2xl">
        <div
          className="h-full"
        >
          <div
            className="p-6 mb-2 rounded-xl bg-grey-light dark:bg-grey-dark text-text-light dark:text-text-dark"
          >
            <div dangerouslySetInnerHTML={{ __html: data.markdown.childMarkdownRemark.html }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PraxenPage;

export const query = graphql`
  query ($language: String!){
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    markdown: file(name: {eq: "Praxen"}) {
      childMarkdownRemark {
        html
      }
    }
  }
`;